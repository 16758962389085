<template>
   <v-container :class=" no_boleta ? 'custom-container fill-height justify-center' : ''">
    <v-card v-if="no_boleta">
      <v-card-title><v-icon left>mdi-file-remove-outline</v-icon>Boleta no encontrada</v-card-title>
      <v-card-text>La boleta asociada al identificador entregado no ha sido encontrada en nuestra plataforma.</v-card-text>
    </v-card>

  </v-container>

</template>

<script>
export default {
  name: "BoletasView",
  data() {
    return {
      no_boleta: false
    }
  },
  created() {

    this.no_boleta = true;
    // if (this.$route.params.numero_boleta == null) {
    //   this.no_boleta = true;
    // } else {
    //   //
    // }
  },
  methods: {}
}
</script>

<style scoped>

</style>